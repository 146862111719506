import React, { useState } from 'react'
import Infotainment from '../assets/infotainment.jpg'
import Megaboard from '../assets/megaboard.jpg'
import Ueberkopf from '../assets/ueberkopf.jpg'
import Ueberkopf2 from '../assets/ueberkopf2.JPG'
import Ueberkopf3 from '../assets/ueberkopf3.JPG'
import Golf from '../assets/golf.jpg'
import Golf2 from '../assets/golf2.jpg'
import Golf3 from '../assets/golf3.jpg'
import Tennis from '../assets/tennis1.jpg'
import Tennis2 from '../assets/tennis2.jpg'

const Gallery = () => {
  const images = [
    Infotainment,
    Megaboard,
    Ueberkopf,
    Ueberkopf2,
    Ueberkopf3,
    Golf,
    Golf2,
    Golf3,
    Tennis,
    Tennis2
  ]

  return (
    <div className='p-5 md:p-10 md:mx-20 text-center' id="gallery">
      <h1 className='text-3xl p-10 font-bold'>Galerie</h1>
      <div className='columns-1 gap-5 lg:gap-8 sm:columns-2 lg:columns-3 xl:columns-4 [&>img:not(:first-child)]:mt-5 lg:[&>img:not(:first-child)]:mt-8'>
      {images.map((image, key) => (
        <img
          key={image}
          className='transition duration-300 transform hover:scale-105 rounded-lg'
          src={image}
          alt="" />
      ))}
      </div>
    </div>
  )
}

export default Gallery