import React from 'react'
import Typed from 'react-typed'

const Home = () => {
  return (
    <div className='pt-10' id="home">
      <div className='max-w-[800px] mt-[-96px] w-full h-screen mx-auto text-center flex flex-col justify-center'>
        <p className='text-[#D05663] font-bold p-2'>SEIT 1985</p>
        <h1 className='md:text-6xl sm:text-5xl text-4xl font-bold md:py-6'>Vor Ort beim Sport.</h1>
        <div className='flex justify-center items-center'>
          <p className='md:text-4xl sm:text-3xl text-xl font-bold py-4'>Außenwerbung für</p>
          <Typed className='md:text-4xl sm:text-3xl text-xl font-bold pl-2 text-[#D05663]' strings={['Alpin', 'Tennis', 'Golf']} typeSpeed={120} backSpeed={140} loop />
        </div>
        <p className='my-2 font-semibold'>Sport macht erfinderisch.</p>
        <p><span className='font-semibold'>Unsere Vorstellung ist einfach: </span>Wir verstärken Ihre Kampagne an Orten, die Ihrer Zielgruppe gefallen. Auf ausgewählten, bestens akzeptierten und frequentierten Pisten, Golf- und Tennisplätzen in ganz Österreich.</p>
        <a className='w-[170px] border border-solid border-black rounded-xl font-medium my-6 mx-auto py-2' href="#contact">Kontakt</a>
      </div>
    </div>
  )
}

export default Home