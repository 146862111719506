import React, { useRef } from 'react'
import { FaBuilding } from "react-icons/fa6";
import { IoLocationSharp } from "react-icons/io5";
import emailjs from '@emailjs/browser';
import { Link } from "react-router-dom";

const Contact = () => {
    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, form.current, process.env.REACT_APP_PUBLIC_KEY)
            .then((result) => {
                console.log(result.text);
                form.current.reset();
            }, (error) => {
                console.log(error.text);
            });
    }

    return (
        <div className='w-full py-16 px-4' id="contact">
            <div className='max-w-[1240px] mx-auto'>
                <p className='text-[#D05663] font-bold uppercase text-center'>KONTAKT</p>
                <h1 className='md:text-4xl sm:text-3xl text-2xl font-bold py-2 text-center'>GET IN TOUCH</h1>
                <div className='grid md:grid-cols-2'>
                    <div>
                        <form ref={form} onSubmit={sendEmail} className='flex flex-col gap-5'>
                            <div><label>Name</label>
                                <input
                                    type='text'
                                    name='name'
                                    placeholder='Name'
                                    required
                                    className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"></input></div>


                            <div>
                                <label>E-Mail</label>
                                <input
                                    type='email'
                                    name='email'
                                    placeholder='E-Mail'
                                    required
                                    className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"></input>
                            </div>
                            <div>
                                <label>Betreff</label>
                                <input
                                    type='text'
                                    name='subject'
                                    placeholder='Betreff'
                                    required
                                    className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"></input>
                            </div>
                            <div>
                                <label htmlFor="message">Nachricht</label>
                                <textarea
                                    rows="4"
                                    name="message"
                                    id="message"
                                    required
                                    className="w-full resize-none rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                                ></textarea>
                            </div>
                            <div className="flex space-x-5">
                                <input type="checkbox" className='accent-[#D05663]' required></input>
                                <label>Ich habe die <Link to="/privacypolicy" className="font-semibold">Datenschutzerklärung</Link> gelesen und bin mit der Verarbeitung meiner Daten einverstanden.</label>
                            </div>
                            <button type='submit' className='border border-solid border-black rounded-xl py-3 hover:bg-[#D05663] hover:text-white'>Senden</button>
                        </form>
                    </div>
                    <div className='flex flex-col p-10 space-y-24'>
                        <div className='flex flex-col text-center mx-auto'>
                            <div className='mx-auto'><FaBuilding className='text-[65px]'></FaBuilding></div>
                            <p className='font-bold'>point of sports</p>
                            <p>office@pointofsports.at</p>
                            <p>+43 (0) 676 60 82 100</p>
                        </div>
                        <div className='flex flex-col text-center mx-auto'>
                            <div className='mx-auto'><IoLocationSharp className='text-[65px]'></IoLocationSharp></div>
                            <p>Grabenberg 109</p>
                            <p>8081 Heiligenkreuz am Waasen</p>
                            <p>Austria</p>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Contact