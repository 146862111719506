import React from 'react'
import Image from '../assets/nature.jpg'

const About = () => {
  return (
    <div className='w-full py-16 px-4' id="about">
      <div className='max-w-[1240px] mx-auto grid md:grid-cols-2'>
        <div className='flex flex-col justify-center'>
          <p className='text-[#D05663] font-bold uppercase'>Unser Erfolg gibt Ihnen Recht</p>
          <h1 className='md:text-4xl sm:text-3xl text-2xl font-bold py-2'>Über uns</h1>
          <p className=''>Seit mehr als 35 Jahren sind wir als verlässlicher Partner erfolgreich mit der Vermittlung, Gestaltung und Produktion exklusiver Werbeflächen beschäftigt und maßgeblich an der (Weiter)entwicklung dieser "Sonder-Werbeformen" beteiligt.</p>
        </div>
        <img className='w-[500px] mx-auto my-4  rounded-2xl' src={Image} alt="/"></img>
      </div>
    </div>
  )
}

export default About