import React, { useEffect } from 'react';
import NavbarSmall from '../components/NavbarSmall';
import ImprintText from '../components/ImprintText';
import Footer from '../components/Footer';

const Imprint = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className='font-Poppins'>
            <NavbarSmall />
            <div className='md:mx-20 mt-32'>
                <ImprintText />
            </div>
            <Footer />
        </div>
    );
}

export default Imprint;