import React from 'react'
import GolfImg from '../assets/golf.jpg'

const Golf = () => {
  return (
    <div className='w-full py-16 px-4' id="golf">
      <div className='max-w-[1240px] mx-auto grid md:grid-cols-2'>
        <div className='flex flex-col justify-center'>
          <p className='text-[#D05663] font-bold uppercase'>Auf dem Grün und entlang des Abschlags</p>
          <h1 className='md:text-4xl sm:text-3xl text-2xl font-bold py-2'>Golf</h1>
          <p className=''>Was früher exklusiv war, ist längst zum Breitensport geworden: Golf. Setzen Sie auf Werbung am "Point-Of-Sports" und Ihre Marke in ein kaufkräftiges Umfeld. Golf-Info-Anlagen mit Funkuhr, Golf Abschlag-Info-Anlagen, Sonderplakatstellen, Abschlagteiler und viele weitere Wege stehen Ihnen auf den attraktivsten Golfplätzen Österreichs offen.</p>
        </div>
        <img className='w-[500px] mx-auto my-4  rounded-2xl' src={GolfImg} alt="/"></img>
      </div>
    </div>
  )
}

export default Golf