import React from 'react'
import { Link } from "react-router-dom";
import Logo from '../assets/logo.png'

const Footer = () => {
  return (
    <div className='max-w-[1240px] mx-auto py-16 px-4 grid lg:grid-cols-2 gap-8 text-gray-800'>
      <div>
        <img src={Logo} alt="POINTOFSPORTS" className='w-44' />
        <p>Ihr Partner für Außenwerbung im Sport und Freizeitbereich. Unsere Außenwerbeflächen sind bestens geeignet für Image- und Markenstärkung.</p>
      </div>
      <div className='lg:col-span-1 flex justify-between mt-6'>
        <div>
          <h6 className='font-medium text-gray-900'>Legal</h6>
          <ul>
            <li className='py-2 text-sm'><Link to="/imprint">Impressum</Link></li>
            <li className='py-2 text-sm'><Link to="/privacypolicy">Datenschutz</Link></li>
          </ul>
        </div>
      </div>
      <p className='text-gray-500 text-xs'>(c) Alle Rechte vorbehalten <span className='font-semibold'>Point Of Sports</span></p>
    </div>
  )
}

export default Footer