import React, { useEffect } from 'react';
import NavbarSmall from '../components/NavbarSmall';
import PrivacyPolicyText from '../components/PrivacyPolicyText';
import Footer from '../components/Footer';

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div div className='font-Poppins' >
      <NavbarSmall />
      <div className='md:mx-20 mt-32'>
        <PrivacyPolicyText />
      </div>
      <Footer />
    </div>
  );
}

export default PrivacyPolicy;