import React from 'react'

const PrivacyPolicyText = () => {
  return (
    <div className="w-full top-0 left-0" id="privacypolicy">
      <div className="items-center max-w-[1240px] mx-auto px-4">
        <h1 className='text-3xl'>Erklärung zur Informationspflicht</h1>
        <div className='mt-4'>
          <h3 className='font-semibold text-lg'>Datenschutzerklärung</h3>
          <p>In folgender Datenschutzerklärung informieren wir Sie über die wichtigsten Aspekte der Datenverarbeitung im Rahmen unserer Webseite. Wir erheben und verarbeiten personenbezogene Daten nur auf Grundlage der gesetzlichen Bestimmungen (Datenschutzgrundverordnung, Telekommunikationsgesetz 2003).

            Sobald Sie als Benutzer auf unsere Webseite zugreifen oder diese besuchen wird Ihre IP-Adresse, Beginn sowie Beginn und Ende der Sitzung erfasst. Dies ist technisch bedingt und stellt somit ein berechtigtes Interesse iSv Art 6 Abs 1 lit f DSGVO.</p>
        </div>

        <div className='mt-10'>
          <h3 className='mb-4 font-semibold text-lg'>Kontakt mit uns</h3>
          <p>Wenn Sie uns, entweder über unser Kontaktformular auf unserer Webseite, oder per Email kontaktieren, dann werden die von Ihnen an uns übermittelten Daten zwecks Bearbeitung Ihrer Anfrage oder für den Fall von weiteren Anschlussfragen für sechs Monate bei uns gespeichert. Es erfolgt, ohne Ihre Einwilligung, keine Weitergabe Ihrer übermittelten Daten.</p>
        </div>

        <div className='mt-10'>
          <h3 className='mb-4 font-semibold text-lg'>Cookies</h3>
          <p>Unsere Website verwendet so genannte Cookies. Dabei handelt es sich um kleine Textdateien, die mit Hilfe des Browsers auf Ihrem Endgerät abgelegt werden. Sie richten keinen Schaden an. Wir nutzen Cookies dazu, unser Angebot nutzerfreundlich zu gestalten. Einige Cookies bleiben auf Ihrem Endgerät gespeichert, bis Sie diese löschen. Sie ermöglichen es uns, Ihren Browser beim nächsten Besuch wiederzuerkennen. Wenn Sie dies nicht wünschen, so können Sie Ihren Browser so einrichten, dass er Sie über das Setzen von Cookies informiert und Sie dies nur im Einzelfall erlauben. Bei der Deaktivierung von Cookies kann die Funktionalität unserer Website eingeschränkt sein.</p>
        </div>

        <div className='mt-10'>
          <h3 className='mb-4 font-semibold text-lg'>Google Fonts</h3>
          <p>Unsere Website verwendet Schriftarten von „Google Fonts“. Der Dienstanbieter dieser Funktion ist:</p>
          <li className='my-2'>Google Ireland Limited Gordon House, Barrow Street Dublin 4. Ireland</li>
          <p className='my-2'>Tel: +353 1 543 1000</p>
          <p className='my-2'>Beim Aufrufen dieser Webseite lädt Ihr Browser Schriftarten und speichert diese in den Cache. Da Sie, als Besucher der Webseite, Daten des Dienstanbieters empfangen kann Google unter Umständen Cookies auf Ihrem Rechner setzen oder analysieren</p>
          <p className='my-2'>Die Nutzung von „Google-Fonts“ dient der Optimierung unserer Dienstleistung und der einheitlichen Darstellung von Inhalten. Dies stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar.</p>
          <p className='my-2'>Weitere Informationen zu Google Fonts erhalten Sie unter folgendem Link:</p>
          <li className='my-2'><a href="https://developers.google.com/fonts/faq">https://developers.google.com/fonts/faq</a></li>
          <p className='my-2'>Weitere Informationen über den Umgang mit Nutzerdaten von Google können Sie der Datenschutzerklärung entnehmen:</p>
          <li className='my-2'><a href="https://policies.google.com/privacy?hl=de">https://policies.google.com/privacy?hl=de</a></li>
          <p className='my-2'>Google verarbeitet die Daten auch in den USA, hat sich jedoch dem EU-US Privacy-Shield unterworfen.</p>
          <li className='my-2'><a href="https://www.privacyshield.gov/EU-US-Framework">https://www.privacyshield.gov/EU-US-Framework</a></li>
        </div>

        <div className='mt-10'>
          <h3 className='mb-4 font-semibold text-lg'>Ihre Rechte als Betroffener</h3>
          <p className='my-2'>Sie als Betroffener haben bezüglich Ihrer Daten, welche bei uns gespeichert sind grundsätzlich ein Recht auf:</p>
          <li className='my-2'>Auskunft</li>
          <li className='my-2'>Löschung der Daten</li>
          <li className='my-2'>Berichtigung der Daten</li>
          <li className='my-2'>Übertragbarkeit der Daten</li>
          <li className='my-2'>Wiederruf und Widerspruch zur Datenverarbeitung</li>
          <li className='my-2'>Einschränkung</li>
          <p className='my-2'>Wenn sie vermuten, dass im Zuge der Verarbeitung Ihrer Daten Verstöße gegen das Datenschutzrecht passiert sind, so haben Sie die Möglichkeit sich bei uns (office@pointofsports.at) oder der Datenschutzbehörde zu beschweren.</p>
        </div>

        <div className='mt-10'>
          <h3 className='mb-4 font-semibold text-lg'>Sie erreichen uns unter folgenden Kontaktdaten:</h3>
          <p className='my-2'><span className='font-semibold'>Webseitenbetreiber:</span> Josef Muhr</p>
          <p className='my-2'><span className='font-semibold'>Telefonnummer:</span> +43 (0) 676 60 82 100</p>
          <p className='my-2'><span className='font-semibold'>Email:</span> office@pointofsports.at</p>
        </div>

        <div className='mt-10'>
          <p>Quelle: <a href="https://www.rechtstexte-generator.at/dsgvo-generator-oesterreich/" className='font-semibold'>Datenschutz DSGVO Generator</a></p>
        </div>
      </div>
    </div>
  )
}

export default PrivacyPolicyText