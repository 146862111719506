import React from 'react'
import TennisImg from '../assets/tennis1.jpg'

const Tennis = () => {
  return (
    <div className='w-full py-16 px-4' id="tennis">
      <div className='max-w-[1240px] mx-auto grid md:grid-cols-2'>
        <img className='w-[500px] mx-auto my-4 rounded-2xl' src={TennisImg} alt="/"></img>
        <div className='flex flex-col justify-center'>
          <p className='text-[#D05663] font-bold'>IMMER AM BALL</p>
          <h1 className='md:text-4xl sm:text-3xl text-2xl font-bold py-2'>Tennis</h1>
          <p>An Tennisplätzen gibt es Grenzen. Allerdings nur für Ball und Spieler. Mit Info-Anlagen und integrierter Funkuhr, attraktiven Transparenten, überdimensionalen Megaboards oder individuellen P.O.S.-Lösungen steht Ihrer Werbung nichts mehr im Weg. Und das heißt nichts anderes als "Spiel, Satz, und Sieg" für Sie, Ihr Unternehmen, Ihr Produkt und Ihre Marke.</p>
        </div>
      </div>
    </div>
  )
}

export default Tennis