import React from 'react'
import Megaboard from '../assets/megaboard.jpg'
import Ueberkopf from '../assets/ueberkopf.jpg'
import Infotainment from '../assets/infotainment.jpg'

const Alpin = () => {
  return (
    <div className='w-full py-10 px-4' id="alpin">
      <h1 className='text-center md:text-4xl sm:text-3xl text-2xl font-bold py-5'>Alpin</h1>
      <p className='max-w-[1240px] text-center mx-auto'>Ob Megaboard, Überkopfanlage, Projektion oder ein völlig neuer Ansatz: Mit einer exklusiven Werbefläche am "Point Of Sports" ist Ihre Botschaft schneller am Berg und auf der Piste als Sie in einer Gondel sind. Großartig. Einfallsreich. Individuell. Von der schlichten Markenpräsenz über Sampling bis zum Life-Action-Advertising ist alles möglich. Lassen Sie sich von den folgenden Beispielen inspirieren.</p>
      <div className='max-w-[1240px] mx-auto grid md:grid-cols-3 gap-8'>
        <div className='w-full shadow-xl flex flex-col my-4 rounded-lg hover:scale-105 duration-300 py-4'>
          <img src={Ueberkopf} alt="/" className='bg-white rounded-t-lg'></img>
          <h2 className='text-2xl font-bold text-center py-3'>Überkopfanlagen</h2>
          <p className='text-center py-2 border-b mx-8'>Unsere Überkopfanlagen bieten nicht nur präzise Zeitinformationen für perfektes Timing auf der Piste, sondern auch die ideale Werbeplattform für maßgeschneiderte Angebote.</p>
        </div>
        <div className='w-full shadow-xl flex flex-col my-4 rounded-lg hover:scale-105 duration-300 py-4'>
          <img src={Megaboard} alt="/" className='bg-white rounded-t-lg'></img>
          <h2 className='text-2xl font-bold text-center py-3'>Megaboardanlagen</h2>
          <p className='text-center py-2 border-b mx-8'>Erreichen Sie Ihre Zielgruppe im großen Stil! Unsere imposanten Megaboard Anlagen bieten die perfekte Leinwand, um Ihre Marke im Rampenlicht zu präsentieren. Mit riesigen Werbeflächen an stark frequentierten Standorten erreichen Sie eine breite und aufgeschlossene Zuschauerschaft.</p>
        </div>
        <div className='w-full shadow-xl flex flex-col my-4 rounded-lg hover:scale-105 duration-300 py-4'>
          <img src={Infotainment} alt="/" className='bg-white rounded-t-lg'></img>
          <h2 className='text-2xl font-bold text-center py-3'>Infotainment Systeme</h2>
          <p className='text-center py-2 border-b mx-8'>Tauchen Sie ein in unsere innovative Welt des Infotainments! Unsere digitalen Überkopfanlagen bieten mehr als nur beeindruckende Bildschirme - sie schaffen fesselnde Erlebnisse für unsere Besucher. Mit dynamischen Displays an zentralen Standorten bieten wir die perfekte Plattform für Ihre Marke, um sich inmitten eines faszinierenden Umfeldes zu präsentieren.</p>
        </div>
      </div>
    </div>
  )
}

export default Alpin