import React, { useEffect } from 'react';
import Navbar from '../components/Navbar';
import Home from '../components/Home';
import About from '../components/About';
import Tennis from '../components/Tennis';
import Alpin from '../components/Alpin';
import Golf from '../components/Golf';
import Footer from '../components/Footer';
import Contact from '../components/Contact';
import Gallery from '../components/Gallery';

const Start = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className='font-Poppins'>
            <Navbar />
            <Home />
            <About />
            <Alpin />
            <Tennis />
            <Golf />
            <Contact />
            <Gallery />
            <Footer />
        </div>
    );
}

export default Start;
