import React, { useEffect, useState } from 'react'
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai'
import { Link } from "react-router-dom";
import Logo from '../assets/logo.png'

const Navbar = () => {
  const [nav, setNav] = useState(false)

  const handleNav = () => {
    setNav(!nav)
  }

  const [active, setActive] = useState(null);
  useEffect(() => {
    const scrollActive = () => {
      setActive(window.scrollY > 20);
    }
    window.addEventListener("scroll", scrollActive);
    return () => window.removeEventListener("scroll", scrollActive)
  }, [active])

  return (
    <div className={`${active ? "shadow-lg bg-Solitude" : ""} fixed w-full top-0 left-0 z-20`}>
      <div className='flex justify-between items-center h-24 max-w-[1240px] mx-auto px-4'>
        <Link to="/"><img src={Logo} alt="POINTOFSPORTS" className='w-44' /></Link>
        <ul className='hidden md:flex font-bold'>
          <li className='px-4 py-2 hover:cursor-pointer'><Link to="/">Home</Link></li>
        </ul>
        <div onClick={handleNav} className='block md:hidden'>
          {nav ? <AiOutlineClose size={20} /> : <AiOutlineMenu size={20} />}
        </div>
        <div className={nav ? 'fixed left-0 top-0 w-[70%] h-full border-r border-r-gray-400 bg-Solitude ease-in-out duration-500' : 'fixed left-[-100%]'}>
          <h1 className='w-full text-3xl font-bold text-[#D05663] m-4'><Link to="/"><img src={Logo} alt="POINTOFSPORTS" className='w-44' /></Link></h1>
          <ul className='uppercase p-4 font-bold'>
            <li className='border-b' onClick={handleNav}><Link to="/" className='block p-4'>Home</Link></li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Navbar