import React from 'react'

const ImprintText = () => {
    return (
        <div className='w-full top-0 left-0'>
            <div className="items-center max-w-[1240px] mx-auto px-4">
                <h1 className="text-3xl">Impressum</h1>
                <p className='font-bold'>Informationen und Offenlegung gemäß §5 (1) ECG, § 25 MedienG, § 63 GewO und § 14 UGB</p>
                <p className='mt-2'><span className='font-bold'>Websitenbetreiber:</span> Josef Muhr</p>
                <p className='mt-2'><span className='font-bold'>Anschrift:</span> Grabenberg 109, 8081 Heiligenkreuz am Waasen</p>

                <p className='mt-6'><span className='font-bold'>UID-Nr:</span> ATU 29552203</p>


                <p className='mt-6'><span className='font-bold'>Kontaktdaten:</span></p>
                <p>Telefon: +43 (0) 676 60 82 100</p>
                <p>Email: office@pointofsports.at</p>

                <p className='mt-6'><span className='font-bold'>Anwendbare Rechtsvorschrift:</span> www.ris.bka.gv.at</p>
                <p className='mt-2'><span className='font-bold'>Berufsbezeichnung:</span> Werbekaufmann</p>

                <p className='mt-6'><span className='font-bold'>Online Streitbeilegung:</span> Verbraucher, welche in Österreich oder in einem sonstigen Vertragsstaat der ODR-VO niedergelassen sind, haben die Möglichkeit Probleme bezüglich dem entgeltlichen Kauf von Waren oder Dienstleistungen im Rahmen einer Online-Streitbeilegung (nach OS, AStG) zu lösen. Die Europäische Kommission stellt eine Plattform hierfür bereit: https://ec.europa.eu/consumers/odr</p>

                <p className='mt-2'><span className='font-bold'>Urheberrecht:</span> Die Inhalte dieser Webseite unterliegen, soweit dies rechtlich möglich ist, diversen Schutzrechten (z.B dem Urheberrecht). Jegliche Verwendung/Verbreitung von bereitgestelltem Material, welche urheberrechtlich untersagt ist, bedarf schriftlicher Zustimmung des Webseitenbetreibers.</p>

                <p className='mt-2'><span className='font-bold'>Haftungsausschluss:</span> Trotz sorgfältiger inhaltlicher Kontrolle übernimmt der Webseitenbetreiber dieser Webseite keine Haftung für die Inhalte externer Links. Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich. Sollten Sie dennoch auf ausgehende Links aufmerksam werden, welche auf eine Webseite mit rechtswidriger Tätigkeit/Information verweisen, ersuchen wir um dementsprechenden Hinweis, um diese nach § 17 Abs. 2 ECG umgehend zu entfernen.
                    Die Urheberrechte Dritter werden vom Betreiber dieser Webseite mit größter Sorgfalt beachtet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden derartiger Rechtsverletzungen werden wir den betroffenen Inhalt umgehend entfernen.</p>

                <p className='mt-4'>Quelle: <b><a href="https://www.rechtstexte-generator.at/dsgvo-generator-oesterreich/">Datenschutz DSGVO Generator</a></b></p>
            </div>
        </div>
    )
}

export default ImprintText